<template>
  <label
    :class="{
      'v-w-100 v-d-flex v-ui-switch v-pointer': true,
      disabled: disabled,
      'v-flex-row-reverse': switchAfterLabel,
      className
    }"
    :inert="disabled"
    :aria-label="label.toString()"
  >
    <span class="v-ui-switch__wrapper">
      <input
        :id="uid"
        class="v-switch__input"
        :aria-checked="checked"
        :aria-label="label.toString()"
        :disabled="disabled"
        :inert="disabled"
        type="checkbox"
        v-model="checked"
      />
      <span class="v-ui-switch__knob" />
    </span>
    <span :class="`v-ui-switch__label ${labelClassName ?? ''}`">
      <slot>
        {{ label }}
      </slot>
    </span>
  </label>
</template>

<script setup lang="ts">
import type { VElement } from '@arora/common'

defineProps<
  VElement & {
    switchAfterLabel?: boolean
    labelClassName?: string
  }
>()

const checked = defineModel('checked', { default: false, type: Boolean })
const uid = useId()
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/mixins';

.v-ui-switch {
  gap: 6px;
  align-items: center;

  &.disabled {
    opacity: 1;

    .v-ui-switch__knob {
      background-color: variables.$Mono300;
    }

    .v-ui-switch__wrapper input:checked + .v-ui-switch__knob {
      background-color: variables.$Mono1000;
    }
  }

  &__label {
    flex: 0 0 calc(100% - 40px);
    width: calc(100% - 40px);
  }

  &__wrapper {
    position: relative;
    display: inline-block;

    flex: 0 0 32px;
    width: 32px;
    height: 20px;

    input[type='checkbox'] {
      appearance: none;
    }

    input {
      &:checked {
        + {
          .v-ui-switch__knob {
            background-color: variables.$SecondaryBackgroundColor;

            &:before {
              transform: translateX(12px);
            }
          }
        }
      }

      + {
        .v-ui-switch__knob {
          @include mixins.trans;

          &:before {
            @include mixins.trans;
          }
        }
      }
    }
  }

  &__knob {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: variables.$Mono400;
    border-radius: 40px;

    &:before {
      position: absolute;
      background-color: variables.$Mono0;
      content: '';
      left: 2px;
      top: 2px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
    }
  }
}
</style>
